import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import { JsonDebugger } from "../components/json-debugger"
import {
  Avatar,
  Breadcrumb,
  Card,
  Col,
  Pagination,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd"
import PageContent from "../components/page-content"
import { RichText } from "prismic-reactjs"
import { GatsbyImage } from "gatsby-plugin-image"
import { testimonialPagination } from "./testimonial-page.module.less"
const { Title } = Typography

const PrismicTestimonial = ({ data }) => {
  if (!data) return null
  const testimonial = data.prismicTestimonial.data
  const image = Array.isArray(testimonial.images) ? (
    <GatsbyImage
      alt={""}
      objectFit={"contain"}
      image={testimonial.images[0].image.gatsbyImageData}
    />
  ) : null

  return (
    <Layout>
      <Seo title={testimonial.title} />
      {/* <SliceZone sliceZone={doc.data.body} /> */}
      <PageContent>
        <Space direction="vertical" size="large">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/testimonials/">Testimonials</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{testimonial.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Row wrap={false} gutter={[24, 24]}>
              <Col flex="none">{image}</Col>
              <Col flex="auto">
                <Card.Meta
                  avatar={
                    <Avatar
                      // src={`https://joeschmoe.io/api/v1/${data.prismicTestimonial.id}`}
                      src={`https://www.gravatar.com/avatar/${
                        data.prismicTestimonial.emailHash ??
                        data.prismicTestimonial.id
                      }?d=robohash`}
                    />
                  }
                  title={<Title level={2}>{testimonial.title}</Title>}
                  description={
                    <blockquote>
                      <RichText render={testimonial.message.richText} />
                      <footer>
                        —{" "}
                        <cite className="author">
                          {`${testimonial?.first_name ?? ""}${
                            testimonial.last_name
                              ? " " + testimonial.last_name
                              : ""
                          }${
                            testimonial.job_title
                              ? ", " + testimonial.job_title
                              : ""
                          }
			  `.trim()}
                        </cite>
                        {(testimonial.first_name || testimonial.last_name) &&
                          testimonial.company && <>, </>}
                        <cite className="company">{testimonial.company}</cite>
                      </footer>
                    </blockquote>
                  }
                />
                {Array.isArray(testimonial.products) &&
                  testimonial.products.length > 0 &&
                  testimonial.products[0].product.document && (
                    <>
                      <Title level={4}>Included products</Title>
                      <Avatar.Group size="large">
                        {testimonial.products.map(({ product }, i) => {
                          if (!product?.document?.data) {
                            return
                          }
                          return (
                            <Tooltip
                              title={product.document.data.product_name.text}
                              key={i}
                            >
                              <Link to={product.document.url}>
                                <Avatar
                                  size="large"
                                  src={
                                    product.document.data.shopify_product.image
                                      .src
                                  }
                                >
                                  {product.document.data.product_name.text[0]}
                                </Avatar>
                              </Link>
                            </Tooltip>
                          )
                        })}
                      </Avatar.Group>
                    </>
                  )}
              </Col>
            </Row>
          </Card>
          <Pagination
            className={testimonialPagination}
            current={data?.previous ? 2 : 1}
            // itemRender={(page, type, originalElement) => {
            //   if (type === "page") {
            //     return
            //   }
            //   return originalElement
            // }}
            // simple
            // showTitle={false}
            pageSize={1}
            // defaultPageSize={0}
            total={2 + (data?.next ? 1 : 0)}
            onChange={(page) => {
              if (page >= 2) {
                navigate(data.next.url)
              }
              if (page === 1) {
                navigate(data.previous.url)
              }
            }}
          />
          {/* <Row gutter={[24, 24]} wrap={false}>
            <Col>Previous</Col>

            <Col>Next</Col>
          </Row> */}
        </Space>
        {/* <JsonDebugger json={data} /> */}
      </PageContent>
    </Layout>
  )
}

export const prismicTestimonialQuery = graphql`
  query ($id: String!, $nextId: String, $previousId: String) {
    next: prismicTestimonial(id: { eq: $nextId }) {
      id
      url
      data {
        title
      }
    }
    previous: prismicTestimonial(id: { eq: $previousId }) {
      id
      url
      data {
        title
      }
    }

    prismicTestimonial(id: { eq: $id }) {
      _previewable
      id
      emailHash
      data {
        title
        company
        first_name
        last_name
        job_title
        message {
          richText
        }
        instagram {
          url
        }
        video {
          id
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                id
                url
                data {
                  product_name {
                    text
                  }
                  shopify_product {
                    image {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        images {
          image {
            gatsbyImageData(width: 500, height: 500)
            alt
          }
        }
      }
    }
  }
`

export default PrismicTestimonial
